import React, { useState } from 'react';
import Map from './components/Map';
import Menu from './components/Menu';

const App = () => {
  const [mapLayers, setMapLayers] = useState([]);
  const [shape, setShape] = useState([]);

  return (
    <div style={{position: 'relative'}}>
      <Menu mapLayers={mapLayers} setShape={setShape}/>
      <Map mapLayers={mapLayers} setMapLayers={setMapLayers} shape={shape}/>
    </div>
  );
}

export default App;