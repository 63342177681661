import React, { useEffect, useState } from "react";
import "./Menu.css";
import ScreenQRCode from "./ScreenQRCode";
import downloadQRCode from "../utils/downloadQRCode";
import axios from 'axios';

function Menu({ mapLayers, setShape }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [gpxData, setGpxData] = useState('');
  const [wpt, setWpt] = useState([]); //wpt : waypoint
  const [data, setData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue !== '') {
      setData(data => {
        return { ...data, name: inputValue }
      });
      setShowQRCode(true);
      setInputValue('');
    }
  }

  const handleDownload = () => {
    console.log(mapLayers);
    if (showQRCode) {
      downloadQRCode();
    }
  }

  useEffect(() => {
    setWpt([]);
    setShowQRCode(show => {
      return show && mapLayers.length > 0;
    });
  }, [mapLayers]);

  useEffect(() => {
    mapLayers.forEach((layer) => {
      layer.coords.forEach((coord) => {
        setWpt((waypoint) => [
          ...waypoint,
          `<wpt lat="${coord.lat}" lon="${coord.lng}"/>`
        ]);
      });
    });
  }, [mapLayers]);

  useEffect(() => {
    if (wpt.length > 0)
      setGpxData(
        '<?xml version="1.0"?>\n' +
        '<gpx version="1.1" creator="n-vibe">\n' +
        [...new Set(wpt)].join('\n') +
        '\n</gpx>'
      );
  }, [wpt, mapLayers]);
  // .post(`http://54.216.3.181/api`, gpxData)

  useEffect(() => {
    if (gpxData !== '') {
      axios
        .post(`https://petit-poucet.azurewebsites.net/api`, gpxData)
        .then((res) => {
          setData(res.data.data);
          setShape(res.data.shape);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, [gpxData]);

  return (
    <div className="menu-container">
      <button onClick={() => setIsOpen(!isOpen)}>
        Menu
      </button>
      {isOpen && (
        <div className="menu-content">
          <div className="buttons-menu">
            <button onClick={() => setIsOpen(!isOpen)}>
              Close
            </button>
            <button onClick={handleDownload}>
              Download QR Code
            </button>
          </div>
          {
            (mapLayers.length > 0) &&
            <div>
              <form className="route-name" onSubmit={handleSubmit}>
                <div>
                  Please fill in this field and submit to see the QR Code
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Route name..."
                    value={inputValue}
                    required
                    onChange={e => setInputValue(e.target.value)}
                  />
                  <button>Submit</button>
                </div>
              </form>
            </div>
          }
          <h2>Route drawn</h2>
          <div className="data-infos">
            <pre
              style={{
                overflow: 'auto',
                border: '1px solid black',
                maxWidth: '400px',
                minWidth: '300px',
                backgroundColor: '#302b2b',
                color: 'gold'
              }}
            >
              {mapLayers.length > 0 ? gpxData : null}
            </pre>
            {
              (mapLayers.length > 0 && Object.keys(data).length > 0 && showQRCode) &&
              <ScreenQRCode data={JSON.stringify(data, 0, 2)} />
            }
            {/* <pre>{JSON.stringify(mapLayers, 0, 2)}</pre> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Menu;