import React from 'react';
import QRCode from 'qrcode.react';

function ScreenQRCode({ data }) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <QRCode
        id='qr-canvas'
        value={data}
        size={400}
        includeMargin={true}
      />
    </div>
  );
}

export default ScreenQRCode;