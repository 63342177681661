import { MapContainer, TileLayer, FeatureGroup, Polyline, Popup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

function Map({ mapLayers, setMapLayers, shape }) {
  const handleCreate = e => {
    const { _leaflet_id, _latlngs } = e.layer;
    setMapLayers((layers) => [
      ...layers,
      { id: _leaflet_id, coords: _latlngs }
    ]);
  }

  const handleEdit = e => {
    const { layers: { _layers } } = e;
    Object.values(_layers).forEach(({ _leaflet_id, editing }) => {
      setMapLayers((layers) => layers.forEach((l) =>
        l.id === _leaflet_id ? { ...l, coords: [...editing.latlngs[0]] } : l
      ));
    })
  }

  const handleDelete = e => {
    const { layers: { _layers } } = e;
    Object.values(_layers).forEach(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    })
  }

  return (
    <MapContainer center={[48.858856, 2.379637]} zoom={18} style={{ height: '90vh', width: '100%', border: '1px solid black' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <FeatureGroup
        pathOptions={{ color: 'blue' }}
      >
        <EditControl
          position="topright"
          draw={{
            circle: false,
            rectangle: false,
            polygon: false,
            marker: false,
            circlemarker: false
          }}
          onCreated={handleCreate}
          onEdited={handleEdit}
          onDeleted={handleDelete}
        />
        <Popup>Route drawn</Popup>
      </FeatureGroup>
      {
        (mapLayers.length > 0) &&
        <FeatureGroup>
          <Polyline positions={shape} color='red'>
            <Popup>Geometry from HERE</Popup>
          </Polyline>
        </FeatureGroup>
      }
    </MapContainer>
  );
}

export default Map;